:root {
  --primary-11: #02090b;
  --primary-10: #161C1E;
  --primary-9 : #2E3233;
  --primary-8 : #4B4E4E;
  --primary-7 : #5C5E5F;
  --primary-6 : #737474;
  --primary-5 : #8A8A8A;
  --primary-4 : #A3A4A4;
  --primary-3 : #BABBBB;
  --primary-2 : #D5D5D5;
  --primary-1 : #EEEEEE;

  --primary-color: var(--primary-6); // color used in main actions

  --text-color: black;
  --input-focus-shadow-color: #107f903b; // outer shadow of input on focus - GIVE 20% ALPHA

  --background-color: #f3f3f3;

  --menu-text-color: black;
  --menu-item-active-bg: #B7D8DE; // color of the side menu active ite
  --menu-bg: #F4F9FA; // color of the background of the menu
  --menu-sub-bg: #ffffff; // color of the background of the submenu

  --login-background-color: var(--primary-10);

  --header-logo-background: var(--primary-9);
  --header-background: var(--primary-color);
  --header-text-color: #fff;

  --footer-text-color: #fff;
  --footer-color: var(--primary-5);
}

@primary-11: #02090b;
@primary-10: #161C1E;
@primary-9: #2E3233;
@primary-8: #4B4E4E;
@primary-7: #5C5E5F;
@primary-6: #737474;
@primary-5: #8A8A8A;
@primary-4: #A3A4A4;
@primary-3: #BABBBB;
@primary-2: #D5D5D5;
@primary-1: #EEEEEE;

@primary-color: #737474;
@component-background: #ffffff;
@background-color-base: #ffffff;